
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const LoginInfo    = namespace('LoginInfo');
const CalendarInfo = namespace('CalendarInfo');
const MenuInfo     = namespace('MenuInfo');
const ModalInfo    = namespace('ModalInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';
import { ResizeObserver } from 'vue-resize';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';

declare let Kakao : any;
import '@/assets/js/kakao'

@Component({
    components : {
        ResizeObserver
    }
})
export default class RightUserArea extends Mixins(VueHoduCommon) {
    
    /**
     * @LoginInfo.Action
     */
    @LoginInfo.Action doSetUserName ?: any;

    /** 
     * @CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트

    /**
     * @MenuInfo.State
     */
    @MenuInfo.State user_profile_open !: boolean;

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetUserProfileOpen ?: any;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;


    profile_image_error : boolean = false;
    userImageUrl        : string = require("@/assets/images/contents/im_photoB.gif");
    userDefaultImageUrl : string = require("@/assets/images/contents/im_photoB.gif");

    user_profile_name         : string = "";
    user_profile_phone_number : string = "";

    user_profile_name_temp         : string = "";
    user_profile_phone_number_temp : string = "";

    name_wrong       : boolean = false;
    name_placeholder : string = "이름을 작성하세요";

    phone_wrong       : boolean = false;
    phone_placeholder : string = "입력 된 휴대폰 번호가 없습니다";

    password_change : boolean = false;

    current_password     : string = "";
    new_password         : string = "";
    new_password_confirm : string = "";

    current_password_wrong     : boolean = false;
    new_password_wrong         : boolean = false;
    new_password_confirm_wrong : boolean = false;

    password_error_message : string = "";
    
    beforeMount() : void {
        this.userImageUrl = `/app_images/profile/user/${Math.floor((this.user_id != null ? this.user_id : 0) / 10000)}/${this.user_id}.jpg`;
    }

    mounted() : void {
        this.user_profile_name         = this.user_name         == null ? "" : this.user_name;
        this.user_profile_phone_number = this.user_phone_number == null ? "" : this.user_phone_number;
        this.scrollBarSetting();

        window['user_area_refresh'] = () => {
            this.user_profile_name         = this.user_name         == null ? "" : this.user_name;
            this.user_profile_phone_number = this.user_phone_number == null ? "" : this.user_phone_number;
        };
    }

    beforeDestroy() {
        delete(window['user_area_refresh']);
    }

    profileImageError() : void {
        this.profile_image_error = true;
    }

    userImageClick() : void {
        this.doSetUserProfileOpen(!this.user_profile_open);
    }

    async logout() : Promise<void> {
    
        try {
            if( this.user_type == "KAKAOTALK" ) { 
                if(!Kakao.isInitialized()){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
                Kakao.Auth.logout((data) => {}); 
            }
            
            // 자동로그인 관련 설정
            hodu_local_storage.setItem('auto_login_flag', String(false));
            hodu_cookie.delete("auto_login_flag");
            
            const response = await this.hodu_api_call(`/api/v1/auth/logout`, API_METHOD.GET);

        } catch(e){
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.moveLoginPage();
        }
    }

    moveLoginPage() : void {
        this.hodu_router_push('/login', () => {
                        
            // 세션 없애기
            if(sessionStorage != null && localStorage != null){
                sessionStorage.setItem("session_token", "");
                localStorage.setItem("session_token"  , "");
                hodu_cookie.delete("session_token");
            }
            
            // 로그인 정보 없애기
            this.doLogin({
                isLogin           : false,
                user_id           : 0,
                user_type         : "",
                user_email        : "",
                user_name         : "",
                user_phone_number : "",
                country_code      : "",
                is_temp_password  : false,
                user_preference   : null,
                user_group_role   : null,
                user_team_role    : null,
                template_map      : null,
                auth_info         : {
                    auth_check: false, 
                    auth_check_date: null, 
                    auth_delay_date: null, 
                    auth_check_later: false
                },
                schedule_search_config : {
                    past_schedule     : true,
                    personal_schedule : true,
                    my_schedule       : true,
                    shared_schedule   : true,
                    group_schedule    : true,
                    hodu_c_schedule   : true,
                    group_filter      : [],
                    team_filter       : [],    
                }
            });

            // 프로필 창 닫기
            this.doSetUserProfileOpen(false);

            // 달력 정보 없애기
            this.doSetStartDate(new Date());
            this.doSetCalendarId('');
            this.doSetScope('');  
            this.doSetScopeGroupId(0);  
            this.doSetScopeTeamId(0);  
            this.doSetScopeGroupTeamOption({
                group_team_name : '',
                group_team_color : '#FFFFFF',
                group_team_image : '',
                group_team_descript : '',
                biz_id : '',
                biz_type : ''
            }); 
            
            // datepicker 남아 있는거 제거
            $('#ui-datepicker-div').remove();
        });
    }

    /**
     * 프로필 끄기
     */
    profileCancel() : void {
        this.doSetUserProfileOpen(false);
    }
    
    /**
     * 유저 프로필 저장
     */
    profileSave() : void {
        const vue = this;

        if( this.checkValid() == false ) {
            return;
        }

        // 유저 프로필 수정
        this.hodu_api_call(`api/v1/user/me`, API_METHOD.PUT, {
            "user_name" : this.user_profile_name,
            "user_phone_number" : this.user_profile_phone_number
        }).then((response) => {
            console.log(JSON.stringify(response));

            // 이름, 휴대폰 번호 적용
            vue.doSetUserName(response.data.data.user.user_name);
            vue.doSetUserPhoneNumber(response.data.data.user.user_phone_number);
        })
        .catch((e) => {
            this.hodu_error_process(e, false, false);
        });
        
    }

    /**
     * 값이 올바른지 체크
     */
    checkValid() : boolean {
        this.name_wrong        = false;
        this.name_placeholder  = "이름을 작성하세요";
        this.phone_wrong       = false;
        this.phone_placeholder = '예) 010-0000-0000';

        // 이름 체크
        if( this.user_profile_name.trim().length < 1 ) {
            this.user_profile_name_temp = this.user_profile_name;
            this.name_wrong             = true;
            this.name_placeholder       = "이름을 입력해주세요";
            return false;
        }
        
        // 전화/휴대폰 번호 정규식 체크
        if( this.user_profile_phone_number.trim().length > 0 ) {
            
            // 010-1234-5678 형식인 경우
            if( this.user_profile_phone_number.trim().indexOf('-') > -1 ) {
                if( /^\d{3}-\d{3,4}-\d{4}$/.test(this.user_profile_phone_number.trim()) == false ) {
                    this.user_profile_phone_number_temp = this.user_profile_phone_number;
                    this.user_profile_phone_number      = '';
                    this.phone_wrong                    = true;
                    this.phone_placeholder              = '휴대폰 번호 형식을 확인해주세요 예) 010-0000-0000';
                    return false;
                }
            }

            // 01012345678 형식인경우
            else {
                if( /^\d{3}\d{3,4}\d{4}$/.test(this.user_profile_phone_number.trim()) == false ) {
                    this.user_profile_phone_number_temp = this.user_profile_phone_number;
                    this.user_profile_phone_number      = '';
                    this.phone_wrong                    = true;
                    this.phone_placeholder              = '휴대폰 번호 형식을 확인해주세요 예) 010-0000-0000';
                    return false;
                }     
            }
        }
          
        return true;
    }

    /**
     * wrong인 상태의 name에 포커스가 가면 wrong을 풀고 사용자가 입력한 사용자 이름을 다시 세팅
     */
    nameFocus() : void {
        if( this.name_wrong == false ) {
            return;
        }

        this.user_profile_name = this.user_profile_name_temp;
        this.name_wrong = false;
        this.name_placeholder  = "이름을 작성하세요";
    }

    /**
     * wrong인 상태의 phone number에 포커스가 가면 wrong을 풀고 사용자가 입력한 휴대폰 번호를 다시 세팅
     */
    phoneNumberFocus() : void {

        if( this.phone_wrong == false ) {
            return;
        }

        this.user_profile_phone_number = this.user_profile_phone_number_temp;
        this.phone_wrong = false;
        this.phone_placeholder = '예) 010-0000-0000';
    }

    /**
     * 프로필 이미지 변경
     */
    async profileImageChange(event) : Promise<void> {
        const vue = this;
        const files : File[] = event.target.files;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(files[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#chngePicId').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (1/1), // 1:1
                    image_url : url,
                    cancel_function : async() => {
                        $('#chngePicId').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        await this.hodu_show_indicator();

                        const resize_blob : Blob = await vue.hodu_image_resize(cropped_url);
                        
                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = files[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, files[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = files[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 프로필 이미지 변경
                        const form_data : FormData = new FormData();
                        form_data.append('user_pic', resize_file);
                        
                        await this.hodu_api_call(`api/v1/user/me/pic`, API_METHOD.PUT, form_data)
                            .then(async(response) => {
                                console.log(JSON.stringify(response));
                                vue.doSetImageTime(new Date().getTime());
                                vue.profile_image_error = false;
                            })  
                            .catch(async(e) => {
                                this.hodu_error_process(e, false, false);
                            })
                            .finally(async() => {
                                $('#chngePicId').val("");
                                await this.hodu_hide_indicator();
                            });
                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

        
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * password 변경
     */
    passwordChange() : void {
        this.current_password       = "";
        this.new_password           = "";
        this.new_password_confirm   = "";
        this.password_error_message = "";

        this.password_change = true;
    }

    /**
     * password 변경 모드에서 나가기
     */
    cancelPasswordChange() : void {
        this.password_change = false;
    }

    /**
     * 비밀번호 변경
     */
    async updatePassword() : Promise<void> {
        const vue = this;

        if( await this.checkValidPassword() == false ) {
            return;
        }

        // 비밀번호 변경
        await this.hodu_api_call(`api/v1/user/me/accounts/EMAIL`, API_METHOD.PUT, {
            "old_password" : this.current_password,
            "new_password" : this.new_password
        }).then((response) => {
            console.log(JSON.stringify(response));
            vue.password_change = false;
        })
        .catch((e) => {
            this.hodu_error_process(e, false, false);
        });
    }

    /**
     * 비밀번호 유효성 체크
     */
    async checkValidPassword() : Promise<boolean> {
        this.password_error_message     = "";
        this.current_password_wrong     = false;
        this.new_password_wrong         = false;
        this.new_password_confirm_wrong = false;

        if( this.current_password.trim().length < 1 ) {
            this.password_error_message = "현재 비밀번호를 입력 해주세요";
            this.current_password_wrong = true;
            return false;
        }

        let password_check_flag : boolean = false;

        // 현재 비밀번호가 맞는지 체크
        await this.hodu_api_call(`api/v1/user/me/accounts/passwordCheck/EMAIL`, API_METHOD.POST, {
            "old_password" : this.current_password
        }).then((response) => {
            console.log(JSON.stringify(response));
            password_check_flag = response.data.data.password_check_flag
        })
        .catch((e) => {
            this.hodu_error_process(e, false, false);
        });

        if( password_check_flag == false ) {
            this.password_error_message = "비밀번호 오류! 현재 비밀번호를 확인 해주세요";
            this.current_password_wrong = true;
            return false;
        }

        if( this.new_password.trim().length < 1 ) {
            this.password_error_message = "신규 비밀번호를 입력 해주세요";
            this.new_password_wrong     = true;
            return false;
        }

        if( this.new_password.trim().length < 4 ) {
            this.password_error_message = "신규 비밀번호를 4자리 이상 입력 해주세요";
            this.new_password_wrong     = true;
            return false;
        }

        if( this.new_password_confirm.trim().length < 1 ) {
            this.password_error_message     = "신규 비밀번호 확인을 입력 해주세요";
            this.new_password_confirm_wrong = true;
            return false;
        }

        if( this.new_password.trim() != this.new_password_confirm.trim() ) {
            this.password_error_message     = "신규 비밀번호와 신규 비밀번호 확인이 서로 다릅니다";
            this.new_password_confirm       = "";
            this.new_password_confirm_wrong = true;
            return false;
        }

        if( this.current_password.trim() == this.new_password.trim() ) {
            this.password_error_message = "신규 비밀번호와 현재 비밀번호가 같습니다";
            this.new_password_wrong     = true;
            this.new_password_confirm   = "";
            return false;
        }

        return true;
    }

    @Watch('current_password')
    changeCurrentPassword() : void {
        if( this.current_password_wrong == false ) {
            return;
        } 

        this.current_password_wrong = false;
        this.password_error_message = "";
    }

    @Watch('new_password')
    changeNewPassword() : void {
        if( this.new_password_wrong == false ) {
            return;
        } 

        this.new_password_wrong = false;
        this.password_error_message = "";
    }

    @Watch('new_password_confirm')
    changeNewPasswordConfirm() : void {
        if( this.new_password_confirm_wrong == false ) {
            return;
        } 

        this.new_password_confirm_wrong = false;
        this.password_error_message = "";
    }

    /**
     * 인증
     */
    auth() : void {
        try {
            let popup : Window | null = null;

            window['skip_button_select'] = false;

            window['auth_callback'] = (authResult, auth_check_later) => {
                try {
                    console.log(authResult);
                    this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        try {
                            console.log(user_info);
                            this.doSetAuthInfo(user_info.auth_info);
                            this.doSetUserPhoneNumber(user_info.user_phone_number);
                            this.doSetCountryCode(user_info.country_code);

                            this.user_profile_phone_number = user_info.user_phone_number;
                            
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                    });
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                } finally {
                    popup?.close();
                }
            }

            const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;

            const w = 481;
            const h = 420;
            const l = (width - w) / 2 / systemZoom + dualScreenLeft;
            const t = (height - h) / 2 / systemZoom + dualScreenTop;

            popup = window.open('/firebase-auth.html', `firebase-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 휴대폰 번호 변경
     */
    phoneChange() : void {
        try {
            let popup : Window | null = null;

            window['skip_button_select'] = true;

            window['auth_callback'] = (authResult, auth_check_later) => {
                try {
                    console.log(authResult);
                    this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        try {
                            console.log(user_info);
                            this.doSetAuthInfo(user_info.auth_info);
                            this.doSetUserPhoneNumber(user_info.user_phone_number);
                            this.doSetCountryCode(user_info.country_code);

                            this.user_profile_phone_number = user_info.user_phone_number;
                            
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                    });
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                } finally {
                    popup?.close();
                }
            }

            const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

            const systemZoom = width / window.screen.availWidth;

            const w = 481;
            const h = 420;
            const l = (width - w) / 2 / systemZoom + dualScreenLeft;
            const t = (height - h) / 2 / systemZoom + dualScreenTop;

            popup = window.open('/firebase-auth.html', `firebase-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }
    
    /**
     * 스크롤바 세팅
     */
    scrollBarSetting() : void {
        const windowHeight : number = window.innerHeight;
        const titleHeight : number | undefined = $('.noti_titlebox').outerHeight();

        // @ts-ignore
        $('.notiWrap').mCustomScrollbar({
            axis : 'y',
            setHeight : windowHeight - ( titleHeight == null ? 0 : titleHeight ),
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 리사이즈 핸들러
     */
    handleResize() : void {
        // @ts-ignore
        $('.notiWrap').mCustomScrollbar('destroy');
        this.scrollBarSetting();
    }
    
}

